<template>
  <div class="collection-item-container">

    <div
      @click="goDetails()"
      class="collection-item-content"
    >
      <img
        class="collection-item-img"
        :src="collectionInfo.image"
      />
      <p class="collection-item-title">{{ collectionInfo.name }}</p>
      <limit-item
        style="margin:8px 0 5px 9px;"
        v-if="collectionInfo.limitInfo"
        limitTitle="限量"
        :limitContent="collectionInfo.limitInfo"
      ></limit-item>
      <div class="price-type row-between">
        <p class="good-prise">
          <span style="font-size: 15px;margin-right:3px;">¥</span>
          <span style="font-size: 21px;">{{ String(collectionInfo.price).split('.')[0]}}{{ String(collectionInfo.price).split('.').length == 2 ? '.' : ''}}</span>
          <span
            v-if="String(collectionInfo.price).split('.').length == 2"
            style="font-size: 15px;"
          >{{String(collectionInfo.price).split('.')[1]}}</span>
        </p>
        <img
          class="good-type"
          :src="mediaType[collectionInfo.cateId] == 'img' ? require('../assets/icon/media/img.png') : mediaType[collectionInfo.cateId] == 'audio' ?  require('../assets/icon/media/audio.png'): mediaType[collectionInfo.cateId] == 'video' ?  require('../assets/icon/media/video.png') :  require('../assets/icon/media/3d.png')"
          alt=""
        >
      </div>
      <div
        v-if="collectionInfo.isOpen == 1 && (collectionInfo.stock == 0 || collectionInfo.sellStatus == '已售罄' || collectionInfo.sellStatus == '售完' || collectionInfo.sellStatus == '即将开放' || (collectionInfo.sellStatus == '即将开放' || (collectionInfo.endTime && (new Date(collectionInfo.endTime.replace(/-/g,'/'))).getTime() < (new Date()).getTime())))"
        class="order-sales-status row-center"
        style="background: #000000;border-radius: 16px;opacity: 0.73;"
      >
        <img
          class="index-icon"
          :src="(collectionInfo.sellStatus == '即将开放' || (collectionInfo.endTime && (new Date(collectionInfo.endTime.replace(/-/g,'/'))).getTime() < (new Date()).getTime())) || collectionInfo.stock == 0 || collectionInfo.sellStatus == '已售罄' || collectionInfo.sellStatus == '售完' ? require('../assets/icon/no_goods.png') : require('../assets/icon/time_limit.png')"
        />
        <p class="index-time-tip">{{ (collectionInfo.endTime && (((new Date()).getTime()) > (new Date(collectionInfo.endTime.replace(/-/g,'/'))).getTime())) && collectionInfo.stock != 0 ? '售卖结束' : collectionInfo.stock == 0 || collectionInfo.sellStatus == '已售罄' || collectionInfo.sellStatus == '售完' ? '已售罄' : collectionInfo.startTime ? collectionInfo.startTime.substring(5,collectionInfo.startTime.length - 3) + ' 开放' : '即将开放' }}</p>
      </div>
    </div>

  </div>
</template>
<script>
import LimitItem from '../components/LimitItem.vue'
import config from '../config'
export default {
  props: {
    collectionInfo: {
      type: Object,
      default: {}
    },
  },
  components: {
    LimitItem
  },
  data () {
    return {
      mediaType: config.mediaType
    }
  },
  methods: {
    goDetails () {
      this.$router.push('/goodDetails?id=' + this.collectionInfo.id + '&isBanner=buy')
    },
  },
}
</script>
<style lang="less" scoped>
.collection-item-container {
  width: 45.5vw;
  height: auto;
  margin-bottom: 12px;
  .collection-item-content {
    width: 45.5vw;
    height: calc(45.5vw + 105px);
    background: #090a0a;
    border-radius: 6px;
    position: relative;
    .collection-item-img {
      width: 45.5vw;
      height: 45.5vw;
      border-radius: 6px 6px 0px 0px;
    }
    .collection-item-title {
      width: 90%;
      white-space: pre;
      tab-size: 4;
      margin-left: 5%;
      margin-top: 6px;
      text-align: left;
      font-size: 16px;
      font-family: lantingheiweight;
      font-weight: 400;
      color: #ffffff;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: keep-all;
    }
    .collection-item-limit {
      margin: 8px 0 8px 5%;
    }
  }
  .order-sales-status {
    position: absolute;
    top: 10px;
    left: 10px;
    .index-icon {
      margin-right: 4px;
      margin-left: -6px;
      width: 30px !important;
      height: 30px !important;
    }
  }

  .price-type {
    width: 90%;
    margin-left: 5%;
    margin-top: 10px;
    .good-prise {
      font-family: SFUIDisplay-Bold, SFUIDisplay;
      font-weight: bold;
      color: #ffffff;
      line-height: 25px;
    }
    .good-type {
      width: 20px;
      height: auto;
    }
  }
}
</style>