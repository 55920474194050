<template>
  <div
    v-if="limitTitle || limitContent"
    style="margin-right: 0.5rem;"
    class="row-left"
  >
    <div
      v-if="limitTitle"
      class="good-detail-limited row-center"
    >
      <img
        class="limited-icon"
        src="../assets/icon/set.png"
      />
      <p>{{limitTitle}}</p>
    </div>
    <p :class="limitTitle ? 'limit-amount' : 'limit-amount1'"> {{ limitContent }}</p>
  </div>
</template>
<script>
export default {
  props: {
    limitTitle: {
      type: String,
      default: ''
    },
    limitContent: {
      type: String,
      default: ''
    },
  },
  data () {
    return {

    }
  },
}
</script>
<style lang="less" scoped>
.good-detail-limited {
  min-width: 3.125rem;
  padding: 0 5px;
  width: auto;
  height: 1.25rem;
  background: #f3cdb6;
  border-radius: 0.25rem 0px 0px 0.25rem;
  font-size: 0.75rem;
  font-weight: 600;
  color: #010804;
  line-height: 1.0625rem;
}

.limited-icon {
  margin-right: 0.25rem;
  width: 0.875rem;
  height: 0.875rem;
}
.limit-amount1 {
  padding: 0 10px;
  width: auto;
  min-width: 3.75rem;
  padding: 0 5px;
  width: auto;
  height: 1.25rem;
  text-align: center;
  background: #484848;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-family: NotoSansHans-Medium, NotoSansHans;
  font-weight: 500;
  color: #f3cdb6;
  line-height: 1.25rem;
}
</style>
